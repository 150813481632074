import React, { forwardRef, useState } from 'react';
import { useMutation, useRefresh } from 'react-admin';
import { get } from 'lodash';
import { MenuItem } from '@material-ui/core';
import ActionButton from 'components/buttons/ActionButton';
import { Confirmation } from 'components/common';
import NotificationDialog from 'components/common/NotificationDialog';

const useVoidLabel = (id, options?: { onSuccess?: () => void }) => {
  const [mutate, mutateState] = useMutation();

  const voidLabel = () => {
    return new Promise((resolve, reject) => {
      mutate(
        {
          type: 'post',
          resource: 'voidLabel',
          payload: {
            id,
          },
        },
        {
          onSuccess: resolve,
          onFailure: reject,
        }
      );
    }).then(() => options && options.onSuccess);
  };

  return [voidLabel, mutateState];
};

const VoidLabelButton = ({ data }) => {
  const refresh = useRefresh();
  const [showNotice, setShowNotice] = useState(false);
  const [voidLabel, { loading }] = useVoidLabel(get(data, 'id'), {
    onSuccess: () => {
      const rolloAccount = !!data?.account?.id?.includes('@rollo');
      setShowNotice(rolloAccount);
      if (!rolloAccount) refresh();
    },
  });

  const handleNoticeClose = () => {
    setShowNotice(false);
    refresh();
  };

  if (showNotice) {
    return (
      <NotificationDialog
        open={true}
        title="Void label"
        onConfirm={handleNoticeClose}
        onCancel={handleNoticeClose}>
        <div>
          Your refund request has been received. Please allow up to 2 weeks for it to be approved
          and for funds to return to your balance.
        </div>
      </NotificationDialog>
    );
  }

  if (data.shipmentStatus.status !== 'SHIPPED' || !data.account.id) {
    return null;
  }

  return (
    <Confirmation title="Void label" content="Are you sure you want to void this label?">
      <ActionButton data-test="void-button" disabled={loading} onClick={voidLabel}>
        Void Label
      </ActionButton>
    </Confirmation>
  );
};

const VoidLabelActionField = forwardRef((props: any, ref: any) => {
  const refresh = useRefresh();
  const { record } = props;
  const [showNotice, setShowNotice] = useState(false);
  const [voidLabel] = useVoidLabel(get(props.record, 'id'), {
    onSuccess: () => {
      const isNotice = !!record?.account?.id?.includes('@rollo');
      setShowNotice(!!record?.account?.id?.includes('@rollo'));
      if (!isNotice) {
        if (props.setRates) {
          props.setRates((prev) => [
            ...prev.filter((rate) => rate.orderId !== record.order.id),
            {
              ...prev.find((rate) => rate.orderId === record.order.id),
              voidedLabel: 'Voided',
            },
          ]);
        }
        refresh();
      }
    },
  });

  const handleConfirmNotice = () => {
    if (props.setRates) {
      props.setRates((prev) => [
        ...prev.filter((rate) => rate.orderId !== record.order.id),
        {
          ...prev.find((rate) => rate.orderId === record.order.id),
          voidedLabel: 'Refund Requested',
        },
      ]);
    }
    refresh();
  };

  if (showNotice) {
    return (
      <NotificationDialog
        open={true}
        title="Void label"
        onConfirm={handleConfirmNotice}
        onCancel={() => refresh()}>
        <div>
          Your refund request has been received. Please allow up to 2 weeks for it to be approved
          and for funds to return to your balance.
        </div>
      </NotificationDialog>
    );
  }

  if (
    props.record.shipmentStatus.status !== 'SHIPPED' ||
    !props.record.account.id ||
    props.voidedLabel
  ) {
    return null;
  }

  return (
    <Confirmation title="Void label" content="Are you sure you want to void this label?">
      <MenuItem ref={ref} onClick={voidLabel}>
        Void label
      </MenuItem>
    </Confirmation>
  );
});

export { VoidLabelButton, VoidLabelActionField };
