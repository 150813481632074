import { MenuItem } from '@material-ui/core';
import ActionButton from 'components/buttons/ActionButton';
import React, { forwardRef } from 'react';
import { useRedirect } from 'react-admin';
import { useDispatch } from 'react-redux';
import { openVerification } from 'store/actions/verificationActions';
import { isMilitaryAddress } from 'services/helpers/isMilitaryAddress';

const NON_RETURNABLE_STATUSES = [
  'REFUND_REQUESTED',
  'REFUNDED',
  'REFUND_REJECTED',
  'VOIDED',
  'EXCEPTION',
  'POTENTIALLY_LOST',
];

export const ReturnLabelButton = (data) => {
  const redirect = useRedirect();
  const dispatch = useDispatch();

  const address = data.customer.address;

  if (
    data.type !== 'FORWARD' ||
    NON_RETURNABLE_STATUSES.includes(data.shipmentStatus.status) ||
    isMilitaryAddress(address) ||
    address.countryCode !== 'US'
  ) {
    return null;
  }

  const returnLabel = () => {
    const phoneVerified = JSON.parse(sessionStorage.getItem('phoneVerified') as string);
    if (!phoneVerified && typeof phoneVerified === 'boolean') {
      dispatch(openVerification());
      return;
    }
    redirect(`/shipments/${data.id}/return`);
  };

  return (
    <ActionButton data-test="return-label-button" onClick={returnLabel}>
      Create Return Label
    </ActionButton>
  );
};

export const ReturnLabelActionField = forwardRef((props: any, ref: any) => {
  const redirect = useRedirect();
  const dispatch = useDispatch();

  const address = props.record.customer.address;

  if (
    props.record.type !== 'FORWARD' ||
    NON_RETURNABLE_STATUSES.includes(props.record.status) ||
    props.voidedLabel ||
    isMilitaryAddress(address) ||
    address.countryCode !== 'US'
  ) {
    return null;
  }

  const returnLabel = () => {
    const phoneVerified = JSON.parse(sessionStorage.getItem('phoneVerified') as string);
    if (!phoneVerified && typeof phoneVerified === 'boolean') {
      dispatch(openVerification());
      return;
    }
    redirect(`/shipments/${props.record.id}/return`);
  };

  return (
    <MenuItem ref={ref} onClick={returnLabel}>
      Create return label
    </MenuItem>
  );
});
