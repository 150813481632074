const resources = {
  rates: `/calc/delivery-prices`,
  packageTypes: `/deliveries/enums/package-types`,
  packageTypesFull: `/deliveries/enums/package-types-full`,
  countries: `/dictionaries/countries`,
  states: `/dictionaries/countries/{id}/states`,
  infoStateCity: `/dictionaries/countries/{countryId}/zip/{zipId}/info`,
  labelInfo: `/shipments/{id}/label-info`,
  labelInfoAndPackingSlip: `/shipments/{id}/printLabelAndPackingSlip`,
  voidLabel: `/shipments/{id}/void`,
  additionalDocument: `/{type}/{id}/documents/{code}`,
  packingSlip: `/{type}/{id}/generated/packing-slip`,
  printReceipt: `/payments/receipt/{transactionId}`,
  orders: `/orders`,
  shipments: `/shipments`,
  orderItems: `/orders/{id}/items`,
  stores: `/markets/enums/providers`,
  orderStatuses: `/orders/statuses`,
  markOrderShipped: `/orders/{id}/mark-shipped`,
  shipmentStatuses: `/shipments/statuses`,
  shipmentTypes: `/shipments/types`,
  returnShipments: `/shipments/returns`,
  shipmentAccounts: `/shipment-accounts`,
  shipmentAccountsUpdate: `/shipment-accounts/{id}`,
  recipientsSearch: `/search`,
  contactsSearch: `/search`,
  recipients: ``,
  contacts: ``,
  contactsBrowse: `/browse`,
  contactsAlphabet: `/alphabet`,
  deliveryProviders: `/deliveries/enums/providers`,
  upsLicense: `/shipment-accounts/ups/license`,
  createUpsAccount: `/shipment-accounts/ups`,
  upsAgreemets: `/shipment-accounts/ups/agreements`,
  connectUpsAccount: `/shipment-accounts/ups/connect/{accountId}`,
  reconnectUpsAccount: `/shipment-accounts/ups/login`,
  sellingChannels: `/market-accounts`,
  amazonSellingChannel: `/market-accounts/amazon`,
  amazonReconnectChannel: `/market-accounts/{id}/amazon`,
  amazonMarketplaces: `/market-accounts/amazon/marketplace`,
  etsySellingChannel: `/market-accounts/etsy`,
  etsyReconnectChannel: `/market-accounts/{id}/etsy`,
  etsyShops: `/market-accounts/etsy/{id}/shops`,
  etsyAuthParams: `/market-accounts/etsy/authorization-url`,
  shopifySellingChannel: `/market-accounts/shopify`,
  shopifyReconnectChannel: `/market-accounts/{id}/shopify`,
  shopifyRedirectUrl: `/market-accounts/shopify/auth-url`,
  woocommerceSellingChannel: `/market-accounts/woocommerce`,
  woocommerceReconnectChannel: `/market-accounts/{id}/woocommerce`,
  shippingPresets: `/presets/shipping`,
  shippingPreset: `/presets/shipping/{id}`,
  popularShippingPresets: `/presets/shipping/popular`,
  warehousePresets: `/presets/company-location`,
  ebayChannel: `/market-accounts/ebay`,
  ebayReconnectChannel: `/market-accounts/{id}/ebay`,
  ebayRedirectUrl: `/market-accounts/ebay/redirect-url`,
  bigCommerceChannel: `/market-accounts/bigcommerce`,
  bigCommerceReconnectChannel: `/market-accounts/{id}/bigcommerce`,
  paypalChannel: `/market-accounts/paypal`,
  paypalReconnectChannel: `/market-accounts/{id}/paypal`,
  paypalRedirectUrl: `/market-accounts/paypal/redirect-url`,
  magentoSellingChannel: `/market-accounts/magento`,
  magentoReconnectChannel: `/market-accounts/{id}/magento`,
  magentoValidateConnection: `/market-accounts/magento/validate/{id}`,
  refreshMarketOrders: `/market-accounts/{id}/refresh-orders`,
  updateSellingChannel: `/market-accounts/{id}`,
  refreshAllOrders: `/market-accounts/refresh-orders`,
  billing: `/payments/history`,
  billingBalance: `/payments/balance`,
  billingStats: `/payments/global-stat`,
  paymentOptions: `/payments/options`,
  paymentIntents: `/payments/payment-intents`,
  paymentStatuses: `/payments/enums/statuses`,
  welcomeSettings: `/user/welcome-settings`,
  preferencesSettings: `/user/preferences`,
  helpIndex: `/_help/index.json`,
  help: `/_help/{id}.html`,
  upsAgreement: `/shipment-accounts/ups/discount/{id}/agreement`,
  promoteShipmentAccount: `/shipment-accounts/{id}/promote-ups`,
  applyPromoDiscount: `/shipment-accounts/ups/discount/{id}/apply`,
  scanForms: `/scan-form-options/groups`,
  scanFormsShipments: `/scan-form-options/shipments?filter.date={date}`,
  scanFormsWarehouses: `/scan-forms/warehouses`,
  scanFormsProviders: `/scan-forms/providers`,
  scanFormsHistory: `/scan-forms`,
  printScanForms: `/scan-forms`,
  scanFormsPdfs: `/scan-forms/doc-info`,
  scanFormsAvailableDates: `/scan-forms/dates`,
  validateAddress: `/addresses/validate`,
  shipmentAccountSettings: `/shipment-accounts/{id}/settings`,
  billingSettings: `/payments/billing-settings`,
  removeCard: `/payments/saved-cards`,
  paymentSetting: `/payments/settings`,
  setupIntent: `/payments/setup-intents`,
  enabledPackages: `/calc/package-types`,
  autoRefillOptions: `/payments/auto-refill-options`,
  thresholdOptions: `/payments/threshold-options`,
  verifyInstallAndGetShopifyRedirectUrl: `/market-accounts/shopify/install-app/init`,
  squarespaceChannel: `/market-accounts/squarespace`,
  squarespaceReconnectChannel: `/market-accounts/{id}/squarespace`,
  squarespaceRedirectUrl: `/market-accounts/squarespace/redirect-url`,
  walmartChannel: `/market-accounts/walmart`,
  walmartReconnectChannel: `/market-accounts/{id}/walmart`,
  wixChannel: `/market-accounts/wix`,
  wixReconnectChannel: `/market-accounts/{id}/wix`,
  wixRedirectUrl: `/market-accounts/wix/redirect-url`,
  wixInviteUrl: `/market-accounts/wix/invite-url`,
  createSquareup: `/market-accounts/squareup`,
  createEcwid: `/market-accounts/ecwid`,
  ecwidReconnectChannel: `/market-accounts/{id}/ecwid`,
  squareRedirectUrl: `/market-accounts/square/redirect-url`,
  createSquare: `/market-accounts/square`,
  squareReconnectChannel: `/market-accounts/{id}/square`,
  cart3dRedirectUrl: `/market-accounts/cart3d/redirect-url`,
  createCart3d: `/market-accounts/cart3d`,
  cart3dReconnectChannel: `/market-accounts/{id}/cart3d`,
  ecwidRedirectUrl: `/market-accounts/ecwid/redirect-url`,
  getSquareLocations: `/market-accounts/square/{id}/locations`,
  updateSquareLocation: `/market-accounts/square/{id}/location`,
  bigCartelRedirectUrl: `/market-accounts/bigcartel/redirect-url`,
  createBigCartel: `/market-accounts/bigcartel`,
  userPreferences: `/user/preferences`,
  ordersCSV: `/orders/csv`,
  addressValidate: `/pickups/usps/nearest-date`,
  upsAvailableDates: `/pickups/ups/calendar`,
  upsAvailableShipments: `/pickups/ups/shipments`,
  upsRate: `/pickups/ups/rate`,
  listUspsPickupPackageLocations: `/pickups/usps/package-locations`,
  listUpsPickupPackageLocations: `/pickups/ups/package-locations`,
  createUSPSPickup: `/pickups/usps`,
  createUPSPickup: `/pickups/ups`,
  singlePickupList: `/pickups/single`,
  singlePickupStatuses: `/pickups/single/statuses`,
  automaticPickupList: `/pickups/automatic`,
  pickupHistory: `/pickups/history`,
  bigCartelReconnectChannel: `/market-accounts/{id}/bigcartel`,
  shipmentDates: `/shipments/available-shipment-dates`,
  shipmentDate: `/shipments/default-shipment-date`,
  shipmentDatesCalendar: `/shipments/calendar`,
  warehouseCheckBeforeDelete: `/presets/company-location/{id}/check-before-delete`,
  warehouseCheckBeforeEdit: `/presets/company-location/{id}/check-before-update`,
  groups: `/orders/groups`,
  groupedOrdersIds: `/orders/groups/{id}/ids`,
  groupPrices: `/calc/group-prices`,
  groupSettings: '/orders/groups/{id}/settings',
  amazonRedirectUrl: `/market-accounts/amazon/redirect-url`,
  groupLabels: '/shipments/grouped-labels',
  groupShipments: '/shipments/groups',
  groupShipmentsLabels: '/shipments/groups/{id}/labels',
  groupOrdersInfo: '/orders/groups/{id}/info',
  groupShipmentsInfo: '/shipments/groups/{id}/info',
  deliveryServices: '/deliveries/enums/services',
  groupedShipmentsIds: `/shipments/groups/{id}/ids`,
  userServices: `/orders/groups/user-services`,
  ungroupedExist: `/orders/groups/ungrouped-exist`,
  groupPackingSlips: `/{type}/groups/{id}/packing-slips`,
  multiplePackingSlips: `/{type}/groups/packing-slips`,
  contactsCSV: `/csv`,
  consolidatedShipments: `/shipments/groups/consolidated`,
  exportShipmentsCSV: `/shipments/export`,
  recentFromAddresses: `/addresses/recent/warehouse`,
  warehouseSearch: `/presets/company-location/search`,
  recentContacts: `/recent`,
  addressUsed: `/addresses/used`,
  contactUsed: `/used`,
  connectFedexAccount: `/shipment-accounts/fedex`,
  checkOrders: `/orders/any`,
  billingExport: `/payments/history/export`,
  updateTracking: `/shipments/{id}/status`,
  rolloPickupRates: `/pickups/rollo/rate`,
  rolloPickupAvailable: `/pickups/rollo/available`,
  shipmentsAny: `/shipments/any`,
  statistics: `/statistics`,
  rolloPickupAvailableShipments: `/pickups/rollo/shipments`,
  rolloPickupCalendar: `/pickups/rollo/calendar`,
  rolloPickupRatesBatch: `/pickups/rollo/rate/batch`,
  rolloPickupPackageLocations: `/pickups/rollo/package-locations`,
  rolloPickupCreate: `/pickups/rollo`,
  shipmentMethodGroups: `/orders/shipping-method/`,
  shipmentMethodUngrouped: `/orders/shipping-method/ungrouped`,
  shipmentMethodGroupCreate: `/orders/shipping-method/group`,
  shipmentMethodGroupUpdate: `/orders/shipping-method/group/{id}`,
  skuGroups: `/orders/sku`,
  mergeSkuGroups: `/orders/sku/group/merge`,
  updateSkuGroups: `/orders/sku/group`,
  taxIdTypes: `/customs/tax-id-types`,
  customsShipmentTypes: `/customs/shipment-types`,
  recentCustomsItems: `/products/recent`,
  customsItems: `/products`,
  htsCodes: `/hts/search`,
  landedCost: `/shipments/landed-cost`,
  userTaxIds: `/user/tax-id`,
  orderCustomsInfo: `/shipments/customs-form`,
  paymentLimits: `/payments/limits`,
  deleteAccount: `/user`,
  phoneVerification: `/phone/verification`,
  paymentReview: `/payments/{id}/review`,
  creditCodes: `/credit-codes/codes`,
  inventory: `/inventory`,
  inventorySettings: `/inventory/settings`,
  inventorySettingsUpdate: `/inventory/settings/{id}`,
  inventoryProductHistory: `/inventory/{id}/history`,
  inventoryProductOnHand: `/inventory/{id}/on-hand`,
  inventoryProductStatus: `/inventory/{id}/active`,
  inventoryExistingSku: `/inventory/existing-skus`,
  inventoryBulkProductsStatus: `/inventory/active`,
  tiktokSellingChannel: `/market-accounts/tiktok`,
  tiktokReconnectChannel: `/market-accounts/{id}/tiktok`,
  paymentSession: `/payments/{id}/sessions`,
  tiktokAuthUrl: `/market-accounts/tiktok/url`,
};

export default (resource, params: any = null) =>
  resources[resource] || resources[resource] === ''
    ? params
      ? resources[resource].replace(/{(\w+)}/g, (match, key) => params[key] || params.data[key])
      : resources[resource]
    : resource;
