import React from 'react';
import { get } from 'lodash';
import { createStyles, withStyles } from '@material-ui/core';
import { useUpdate } from 'react-admin';
import { Switch } from 'components/common';
import useCustomNotify from 'hooks/useCustomNotify';

const styles = (theme) =>
  createStyles({
    root: {
      display: 'inline-flex',
      alignItems: 'center',
    },

    label: {
      fontSize: 14,
      color: theme.palette.text.secondary,
    },

    switch: {
      marginLeft: theme.spacing(2),
    },
  });

const SwitchField = (props) => {
  const {
    classes,
    resource,
    record,
    source,
    invert,
    disabled,
    onChange,
    successNotifyText = 'Updated',
    isLoading = false,
  } = props;

  let value = get(record, source);

  const checked = invert ? !value : value;

  const notify = useCustomNotify();

  const [update, { loading }] = useUpdate(resource, record.id, { [source]: !value }, record, {
    onSuccess: () => {
      notify(successNotifyText, 'info');
    },
    onFailure: (error) =>
      notify(
        typeof error === 'string' ? error : error.message || 'ra.notification.http_error',
        'warning'
      ),
  });

  const onSwitch = onChange || update;

  return (
    <div className={classes.root}>
      <span className={classes.label}>{checked ? 'Enabled' : 'Disabled'}</span>
      {!disabled && (
        <Switch
          classes={{ root: classes.switch }}
          checked={checked}
          disabled={loading || isLoading}
          onChange={onSwitch}
        />
      )}
    </div>
  );
};

export default withStyles(styles)(SwitchField);
