import React, { memo, useEffect, useMemo, useState } from 'react';
import { useDataProvider } from 'react-admin';
import { Form } from 'react-final-form';
import classNames from 'classnames';
import { CircularProgress, Paper, makeStyles, Grid } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import { isEqual } from 'lodash';
import { useSelector } from 'react-redux';

import ZipInput from '../../../calculator/components/ZipInput';
import useCustomNotify from 'hooks/useCustomNotify';
import { FromLocation } from 'icons';
import SubmitButton from 'components/buttons/SubmitButton';
import { FormErrorLabel } from 'components/form';
import { useCreatePreset } from 'pages/warehousePresets/utils';
import { get } from 'lodash';
import FormLeftColumn from './FormLeftColumn';
import FormRightColumn from './FormRightColumn';
import { getDefaultShipmentDate } from '../../../../services/helpers/defaultShipmentDateHelpers';
import { weightFormValidator } from 'components/form/validationRules';
import Dialog from 'components/common/Dialog';
import RolloDeliveryPromo from 'components/rolloDelivery/RolloDeliveryPromo';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: theme.spacing(2),
    marginTop: theme.spacing(4),
  },
  defaultHeader: {
    borderTopLeftRadius: theme.spacing(5.5),
    borderTopRightRadius: theme.spacing(5.5),
    marginTop: 0,
  },
  header: {
    background: theme.palette.secondary.light,
    borderRadius: theme.spacing(5.5),
    padding: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
  content: {
    padding: theme.spacing(1, 4, 4),
  },
  error: {
    padding: theme.spacing(0, 4),
  },
  footer: {
    padding: theme.spacing(3, 4, 4),
  },
}));

const requiredZip = (address) => {
  return address?.postalCode ? undefined : 'Required';
};

const RatesProgress = () => {
  const [text, setText] = useState('Requesting Rates');

  useEffect(() => {
    setInterval(() => setText('Calculating Optimal Rate'), 2000);
  }, []);

  return (
    <>
      <CircularProgress size={24} />
      &nbsp;{text}
    </>
  );
};

const GroupedForm = (props) => {
  const dataProvider = useDataProvider();
  const [showConfirm, setShowConfirm] = useState<boolean>(false);
  const [prevFromId, setPrevFromId] = useState<string>('');
  const [showDeliveryPromo, setShowDeliveryPromo] = useState<boolean>(false);
  const [latestWarehouses, setLatestWarehouses] = useState<any>(null);
  const classes = useStyles();
  const notify = useCustomNotify();
  const { id } = useParams();
  const createPreset = useCreatePreset();

  const rolloAccountId = useSelector((store) => store.rolloDelivery.rolloAccountId);

  const {
    selectedIds,
    onSubmit,
    warehousePresets,
    carriers,
    packageTypes,
    updatedGroupRule,
    setUpdatedGroupRule,
    groupSettings,
    showGroupSwitch,
    services,
    deliveryTimes,
    setGroupSettings,
  } = props;

  useEffect(() => {
    dataProvider.getList('recentFromAddresses', { limit: 1 }).then(({ data }) => {
      setLatestWarehouses(data);
    });
  }, [dataProvider]);

  const createGroupSettings = (data) => {
    const {
      selectedCarriers,
      selectedService,
      requestCarrier,
      from,
      options,
      shipmentDate,
      packageInfo,
    } = data;
    const requestData = {
      carriers: deliveryTimes.some((time) => time.code === selectedService)
        ? selectedCarriers
        : requestCarrier,
      companyLocationPresetId: from.id,
      deliveryServiceCode: selectedService,
      insurance: options.insurance,
      signature: options.signature,
      shipmentDate: shipmentDate,
      packageInfo: {
        ...packageInfo[0],
      },
      orders: selectedIds,
    };

    dataProvider
      .create('groupSettings', {
        id,
        data: requestData,
      })
      .then(() => setGroupSettings(requestData))
      .catch((error) => notify(error.message));
  };

  const getCarriers = (savedCarriers) => {
    if (!savedCarriers) return undefined;
    const filteredCarriers = savedCarriers?.length
      ? carriers.filter((carrier) => savedCarriers.includes(carrier.provider.code))
      : carriers;
    return filteredCarriers?.map((carrier) => carrier.provider.code);
  };

  const initData = useMemo(
    () => ({
      from: warehousePresets
        ? updatedGroupRule?.from ??
          warehousePresets?.find(
            (preset) => preset.id === groupSettings?.companyLocationPresetId
          ) ??
          (latestWarehouses?.length ? latestWarehouses[0] : warehousePresets[0])
        : null,
      packageInfo: [
        {
          additionalHandling:
            updatedGroupRule?.packageInfo[0]?.additionalHandling ??
            groupSettings?.packageInfo?.additionalHandling ??
            null,
          id: updatedGroupRule?.packageInfo[0]?.id ?? groupSettings?.packageInfo?.id ?? null,
          name:
            updatedGroupRule?.packageInfo[0]?.name ?? groupSettings?.packageInfo?.name ?? 'Custom',
          type:
            updatedGroupRule?.packageInfo[0]?.type ?? groupSettings?.packageInfo?.type ?? 'PACKAGE',
          size: {
            width:
              updatedGroupRule?.packageInfo[0]?.size?.width ??
              groupSettings?.packageInfo?.size?.width ??
              null,
            height:
              updatedGroupRule?.packageInfo[0]?.size?.height ??
              groupSettings?.packageInfo?.size?.height ??
              null,
            length:
              updatedGroupRule?.packageInfo[0]?.size?.length ??
              groupSettings?.packageInfo?.size?.length ??
              null,
          },
          weight: {
            lb:
              updatedGroupRule?.packageInfo[0]?.weight?.lb ??
              groupSettings?.packageInfo?.weight?.lb ??
              null,
            oz:
              updatedGroupRule?.packageInfo[0]?.weight?.oz ??
              groupSettings?.packageInfo?.weight?.oz ??
              null,
            weightInOz:
              updatedGroupRule?.packageInfo[0]?.weight?.weightInOz ??
              groupSettings?.packageInfo?.weight?.weightInOz ??
              null,
          },
          insuredValue:
            updatedGroupRule?.packageInfo[0]?.insuredValue ??
            groupSettings?.packageInfo?.insuredValue ??
            null,
          cost: updatedGroupRule?.packageInfo[0]?.cost ?? groupSettings?.packageInfo?.cost ?? null,
        },
      ],
      options: {
        signature: updatedGroupRule?.options?.signature ?? groupSettings?.signature ?? false,
        insurance: updatedGroupRule?.options?.insurance ?? groupSettings?.insurance ?? false,
      },
      selectedCarriers:
        getCarriers(updatedGroupRule?.selectedCarriers) ??
        getCarriers(groupSettings?.carriers) ??
        getCarriers([]),
      selectedService:
        updatedGroupRule?.selectedService ??
        groupSettings?.deliveryServiceCode ??
        '__FILTER_UPTO_3_DAYS',
      requestCarrier: updatedGroupRule?.requestCarrier ?? groupSettings?.carriers ?? [],
      packageName: '',
      shipmentDate:
        updatedGroupRule?.shipmentDate ?? getDefaultShipmentDate(moment().format('YYYY-MM-DD')),
      isGroupSave: true,
      availableServices: [...deliveryTimes, ...services],
    }),
    [groupSettings, deliveryTimes, services, warehousePresets, updatedGroupRule, latestWarehouses] // eslint-disable-line
  );

  const initRatesCalculating = (data) => {
    if (data.isGroupSave) {
      createGroupSettings(data);
    }

    if (data.isPackageSave) {
      const packageType = packageTypes.find((item) => item.code === data.packageInfo[0].type);
      return createPreset({
        packageName: data.packageName,
        packageInfo: data.packageInfo[0],
        packageType,
      })
        .then(() => {
          notify('Preset was saved', 'success');
          onSubmit(data, id);
        })
        .catch((error) => {
          notify(error.message, 'warning');
        });
    }

    onSubmit(data, id);
  };

  const handleSubmit = (data) => {
    setUpdatedGroupRule(data);
    const groupRuleToCompare = {
      from: warehousePresets?.find(
        (preset) => preset.id === groupSettings?.companyLocationPresetId
      ),
      packageInfo: [
        {
          ...groupSettings.packageInfo,
          name: 'Custom',
        },
      ],
      options: {
        insurance: groupSettings.insurance,
        signature: groupSettings.signature,
      },
      selectedCarriers: groupSettings.carriers,
      selectedService: groupSettings?.deliveryServiceCode,
      packageName: '',
      shipmentDate: null,
      isGroupSave: true,
      availableServices: null,
      requestCarrier: null,
    };

    const formDataToCompare = {
      ...data,
      availableServices: null,
      packageInfo: [
        {
          ...data.packageInfo[0],
          cost: null,
        },
      ],
      requestCarrier: null,
      shipmentDate: null,
    };

    if (!isEqual(groupRuleToCompare, formDataToCompare) && !showGroupSwitch && data.isGroupSave) {
      setShowConfirm(true);
      return;
    }
    initRatesCalculating(data);
  };

  const handleCancel = () => {
    setShowConfirm(false);
    initRatesCalculating({ ...updatedGroupRule, isGroupSave: false });
  };

  const handleConfirm = () => {
    setShowConfirm(false);
    initRatesCalculating(updatedGroupRule);
  };

  const handleClose = () => {
    setShowConfirm(false);
  };

  if (!warehousePresets) {
    return <CircularProgress />;
  }

  return (
    <>
      <Dialog
        open={showConfirm}
        confirmText="Yes"
        cancelText="No"
        title="Shipment Rule Update"
        onCancel={handleCancel}
        onConfirm={handleConfirm}
        onClose={handleClose}>
        Would you like to save your current selection as the new shipment rule?
      </Dialog>

      <Form
        onSubmit={handleSubmit}
        initialValues={initData}
        validate={weightFormValidator(
          packageTypes,
          'packageInfo[0].type',
          'packageInfo[0].weight.lb',
          'packageInfo[0].weight.oz'
        )}
        render={({ handleSubmit, submitting, form, values, ...renderProps }) => {
          const packageType = packageTypes?.find(
            (i) => i.code === get(values, 'packageInfo[0].type')
          );

          const onTooltipClick = (e) => {
            e.preventDefault();
          };

          const currentOrders = JSON.parse(sessionStorage.getItem('selectedOrders') ?? '[]');
          if (
            !currentOrders?.some(
              (order) => order.shippingAddress.city.toLowerCase() !== 'las vegas'
            ) &&
            values.from?.city.toLowerCase() === 'las vegas' &&
            prevFromId !== values.from?.id &&
            rolloAccountId
          ) {
            setTimeout(() => setShowDeliveryPromo(true), 200);
          }

          if (prevFromId !== values.from?.id) {
            setPrevFromId(values.from?.id);
          }

          const handleClosePromo = () => {
            setShowDeliveryPromo(false);
          };

          const handleConfirmPromo = () => {
            setShowDeliveryPromo(false);
            const rolloRatesRequestData = {
              ...values,
              requestCarrier: ['ROLLODELIVERY'],
              selectedService: 'GROUND_DELIVERY',
            };
            initRatesCalculating(rolloRatesRequestData);
          };

          return (
            <>
              <Paper className={classNames(classes.root, classes.defaultHeader)}>
                <form onSubmit={handleSubmit}>
                  <Paper className={classes.header}>
                    <ZipInput
                      name="from"
                      label="From ZIP Code"
                      icon={<FromLocation style={{ color: '#24C746' }} />}
                      warehousePresets={warehousePresets}
                      validate={requiredZip}
                      redirectAfterSubmit={`orders/groups/${id}/calculate`}
                    />
                  </Paper>
                  <Grid container className={classes.content}>
                    <FormLeftColumn
                      carriers={carriers}
                      form={form}
                      values={values}
                      packageType={packageType}
                      isAdjustForm={false}
                      onTooltipClick={onTooltipClick}
                      showGroupSwitch={showGroupSwitch}
                      groupSettings={groupSettings}
                      updatedGroupRule={updatedGroupRule}
                      deliveryTimes={deliveryTimes}
                    />
                    <FormRightColumn form={form} values={values} isAdjustForm={false} />
                  </Grid>
                  <FormErrorLabel className={classes.error} {...renderProps} />
                  <Grid container className={classes.footer} justify="center">
                    <SubmitButton
                      label="Calculate Shipping Rates"
                      size="large"
                      color="primary"
                      fullWidth={false}
                      submitting={submitting}
                      disabled={warehousePresets?.length === 0}
                      progress={<RatesProgress />}
                    />
                  </Grid>
                </form>
              </Paper>
              <RolloDeliveryPromo
                open={showDeliveryPromo}
                onCancel={handleClosePromo}
                onConfirm={handleConfirmPromo}
              />
            </>
          );
        }}
      />
    </>
  );
};

export default memo(GroupedForm);
