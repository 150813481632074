import React, { memo, useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { useDataProvider, useQueryWithStore, useRefresh } from 'react-admin';
import Auth from '@aws-amplify/auth';
import { fetchEventSource } from '@microsoft/fetch-event-source';
import { useDispatch } from 'react-redux';
import { getAnalytics, logEvent } from 'firebase/analytics';

import useCustomNotify from 'hooks/useCustomNotify';
import { GroupInfo } from './GroupedOrders';
import GroupedForm from './GroupedForm';
import GroupedPrices from './GroupedPrices';
import { openDoc } from 'store/actions/lightboxActions';
import { CircularProgress } from '@material-ui/core';
import useRefillDialog from 'hooks/useRefillDialog';
import mapRateEmailWarning from '../../utils/mapRateEmailWarning';
import FromPhoneModal from './FromPhoneModal';
import { setRolloDeliveryAvailability } from 'store/actions/rolloDelivery';

let controller;

const GroupedCalculator = (props) => {
  const dataProvider = useDataProvider();
  const notify = useCustomNotify();
  const dispatch = useDispatch();
  const refill = useRefillDialog();
  const refresh = useRefresh();
  const location = useLocation();
  const deliveryTimes = [
    {
      code: '__FILTER_UPTO_1_DAYS',
      name: 'Up to 1 Day',
    },
    {
      code: '__FILTER_UPTO_3_DAYS',
      name: 'Up to 3 Days',
    },
    {
      code: '__FILTER_UPTO_5_DAYS',
      name: 'Up to 5 Days',
    },
    {
      code: '__FILTER_CHEAPEST',
      name: '5+ Days',
    },
  ];

  const { id } = useParams();

  const [showRates, setShowRates] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [warehousePresets, setWarehousePresets] = useState<any>(null);
  const [carriers, setCarriers] = useState<any>([]);
  const [lastShippingInfo, setLastShippingInfo] = useState<any>({});
  const [rates, setRates] = useState<any>([]);
  const [selectedIds, setSelectedIds] = useState<any>(
    JSON.parse(sessionStorage.getItem('selectedIds') as string)
  );
  const [group, setGroup] = useState<any>({});
  const [labelsProcessingText, setLabelsProcessingText] = useState<string>('Calculating Rates');
  const [updatedGroupRule, setUpdatedGroupRule] = useState<any>(null);
  const [successfulShipments, setSuccessfulShipments] = useState<any>([]);
  const [groupSettings, setGroupSettings] = useState<any>({});
  const [showGroupSwitch, setShowGroupSwitch] = useState<boolean>(true);
  const [services, setServices] = useState<any>([]);
  const [recipientTrackingEmail, setRecipientTrackingEmail] = useState<boolean>(false);
  const [shipmentsExist, setShipmentsExist] = useState<boolean>(true);
  const [showFixModal, setShowFixModal] = useState<boolean>(false);

  useEffect(() => {
    dataProvider
      .get('shipmentsAny')
      .then(({ data }) => setShipmentsExist(data))
      .catch(() => setShipmentsExist(true));
  }, [dataProvider]);

  useEffect(() => {
    dataProvider
      .getNest('groupOrdersInfo', { id })
      .then(({ data }) => {
        setGroup(data);
      })
      .catch((error) => notify(error.message));
  }, [dataProvider, id, notify]);

  const { data: packageTypes } = useQueryWithStore({
    type: 'getList',
    resource: 'packageTypesFull',
  });

  useEffect(() => {
    dataProvider
      .getList('warehousePresets', {
        pagination: { perPage: 100 },
      })
      .then(({ data }) => setWarehousePresets(data))
      .catch((error) => notify(error.message));
  }, [dataProvider, notify]);

  useEffect(() => {
    dataProvider
      .getList('shipmentAccounts', {
        pagination: {
          page: 1,
          perPage: 20,
        },
        sort: {
          field: 'alias',
          order: 'DESC',
        },
      })
      .then(({ data }) => {
        setCarriers(
          data.filter((carrier) => {
            if (carrier.provider.code === 'UPS') {
              return (
                (!carrier.disabled && !carrier.canBePromoted) ||
                (carrier.disabled && carrier.canBePromoted)
              );
            }
            return !carrier.disabled;
          })
        );
        dispatch(
          setRolloDeliveryAvailability(
            data?.find((carrier) => carrier?.provider?.code === 'ROLLODELIVERY')?.id
          )
        );
      });
  }, [dataProvider, dispatch]);

  useEffect(() => {
    dataProvider
      .getNest('groupSettings', { id })
      .then(({ data }) => {
        setGroupSettings(data);
        if (!!data) setShowGroupSwitch(false);
      })
      .catch(() => setShowGroupSwitch(true));
  }, [dataProvider, id]);

  useEffect(() => {
    if (!carriers?.length) return;
    let newAvailableServices: any = [];
    const requestData = updatedGroupRule?.packageInfo?.length
      ? updatedGroupRule.packageInfo[0]
      : groupSettings.packageInfo;
    Promise.all(
      carriers.map((carrier) => {
        return dataProvider
          .get('deliveryServices', {
            account: carrier.id,
            type: requestData?.type || 'PACKAGE',
            'weight.lb': requestData?.weight?.lb || 0,
            'weight.oz': requestData?.weight?.oz || 0,
            'size.length': requestData?.size?.length || 0,
            'size.height': requestData?.size?.height || 0,
            'size.width': requestData?.size?.width || 0,
            filter: 'DOMESTIC',
          })
          .then(({ data }) => {
            const mappedResponse = data.map((service) => ({
              ...service,
              carrier: carrier.provider.code,
            }));
            newAvailableServices = [...newAvailableServices, ...mappedResponse];
          });
      })
    )
      .then(() => {
        setServices(newAvailableServices);
      })
      .catch((error) => notify(error.message));
  }, [carriers, dataProvider, groupSettings?.packageInfo, notify, updatedGroupRule?.packageInfo]);

  useEffect(() => {
    dataProvider
      .get('preferencesSettings')
      .then(({ data }) => setRecipientTrackingEmail(data.sendEmail))
      .catch(() => setRecipientTrackingEmail(false));
  }, [dataProvider]); // eslint-disable-line

  const setNoInternetTimeout = (delay) =>
    setTimeout(() => {
      notify(
        <div style={{ textAlign: 'left' }}>
          You're not connected to the Internet.
          <br />
          Don't worry! Your labels are still being purchased and will be ready to print soon.
        </div>,
        'connection'
      );
    }, delay);

  const removeNoInternetTimeout = (timeoutId) => clearTimeout(timeoutId);

  const calculateGroupPrices = (data) => {
    controller = new AbortController();
    setIsLoading(true);
    setShowRates(true);
    setLabelsProcessingText('Calculating Rates');
    setRates([]);
    const {
      requestCarrier,
      selectedCarriers,
      from,
      selectedService,
      options,
      shipmentDate,
      packageInfo,
      fromPhoneOverride,
    } = data;
    const packageType = packageTypes.find((item) => item.code === packageInfo[0].type);
    const requestData = {
      carriers: packageType.provider
        ? [packageType.provider]
        : deliveryTimes.some((time) => time.code === selectedService)
        ? selectedCarriers
        : requestCarrier,
      companyLocationPresetId: from.id,
      deliveryServiceCode: selectedService,
      insurance: options.insurance,
      signature: options.signature,
      shipmentDate: shipmentDate,
      packageInfo: {
        ...packageInfo[0],
      },
      orders: selectedIds,
      fromPhoneOverride,
    };
    const shippingInfo = {};
    selectedIds.forEach((id) => (shippingInfo[`${id}`] = data));
    const currentRates: any = [];

    Auth.currentSession()
      .then((aws) => {
        const token = aws['idToken'].jwtToken;
        fetchEventSource(`${process.env.REACT_APP_DATA_PROVIDER_URL}/calc/group-prices`, {
          method: 'POST',
          headers: {
            Accept: 'text/event-stream',
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify([requestData]),
          signal: controller.signal,
          async onopen(res) {
            if (res.ok && res.status >= 200 && res.status < 300) {
              setIsLoading(true);
              setRates([]);
            } else {
              const response = await res.json();
              throw response;
            }
          },
          onmessage(event) {
            const parsedData = mapRateEmailWarning(JSON.parse(event.data), recipientTrackingEmail);

            currentRates.push(parsedData);
            setRates((prev) =>
              parsedData.price?.alerts?.length || parsedData.price?.messages?.length
                ? [
                    {
                      ...parsedData,
                      isShipped: false,
                    },
                    ...prev,
                  ]
                : [...prev, { ...parsedData, isShipped: false }]
            );
          },
          onclose() {
            setIsLoading(false);
            if (
              currentRates.some((rate) =>
                rate.price.alerts.some((error) => error.code === 'contactFrom')
              )
            )
              setShowFixModal(true);
          },
          onerror(error) {
            throw error;
          },
        }).catch((error) => {
          setIsLoading(false);
          setShowRates(false);
          notify(error.message);
        });
      })
      .catch((error) => notify(error.message));
    setLastShippingInfo(shippingInfo);
  };

  const buyLabels = (data) => {
    const shipments: any = [];
    if (rates.some((rate) => rate?.price?.alerts?.length)) {
      const filteredRates = rates.filter(
        (rate) => !rate.price?.alerts?.length || !selectedIds.includes(rate.orderId)
      );
      setRates(filteredRates);
      setSelectedIds(filteredRates.map(({ orderId }) => orderId));
    }
    let receiveTimeout;
    const delay = 30000;
    Auth.currentSession()
      .then((aws) => {
        const token = aws['idToken'].jwtToken;
        receiveTimeout = setNoInternetTimeout(delay);
        fetchEventSource(`${process.env.REACT_APP_DATA_PROVIDER_URL}/shipments/group`, {
          method: 'POST',
          headers: {
            Accept: 'text/event-stream',
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ ...data, orderGroup: id }),
          async onopen(res) {
            if (res.ok && res.status === 200) {
              setIsLoading(true);
            } else {
              removeNoInternetTimeout(receiveTimeout);

              const errorResponse = await res.clone().json();

              if (errorResponse.message?.includes('review')) {
                throw new Error(
                  'This payment requires a manual review. Please allow up to 1 business day. Check status under Deposit tab.'
                );
              }

              if (res.status === 402) {
                refresh();
                refill(false);
                return;
              }

              throw errorResponse;
            }
          },
          onmessage(event) {
            removeNoInternetTimeout(receiveTimeout);
            const parsedData = JSON.parse(event.data);
            shipments.push(parsedData);
            const currentRate = rates.find((rate) => rate.orderId === parsedData.order.id);
            if (!currentRate) return;
            if (!parsedData.errors.length) {
              setRates((prev) => [
                ...prev.filter((rate) => rate.orderId !== parsedData.order.id),
                {
                  ...currentRate,
                  isShipped: true,
                  price: { ...currentRate.price, messages: parsedData.messages ?? [] },
                },
              ]);
            } else {
              setRates((prev) => [
                ...prev.filter((rate) => rate.orderId !== parsedData.order.id),
                {
                  ...currentRate,
                  price: {
                    ...currentRate.price,
                    alerts: [
                      ...parsedData.errors
                        ?.map((error) => {
                          const internalErrors = error.description.split('\n');
                          return internalErrors.map((intError) => ({
                            code: error.code,
                            description: intError,
                          }));
                        })
                        ?.flat(),
                    ],
                    messages: parsedData.messages ?? [],
                  },
                },
              ]);
              setSelectedIds((prev) => prev.filter((id) => id !== currentRate.orderId));
            }
            receiveTimeout = setNoInternetTimeout(delay);
          },
          async onclose() {
            removeNoInternetTimeout(receiveTimeout);
            const successfullyShipped = shipments?.filter((shipment) => !shipment.errors.length);
            if (successfullyShipped?.length) {
              setSuccessfulShipments((prev) => [...prev, ...successfullyShipped]);
              setLabelsProcessingText('Printing labels');
              const {
                data: { url },
              } = await dataProvider.create('groupLabels', {
                data: successfullyShipped?.map(({ id }) => id),
              });
              const warehouseId = lastShippingInfo[rates[0]?.orderId]?.from?.id;
              if (lastShippingInfo.fromPhoneOverride) {
                const currentWarehouse = warehousePresets.find(
                  (warehouse) => warehouse.id === warehouseId
                );
                await dataProvider.update('warehousePresets', {
                  id: currentWarehouse.id,
                  data: {
                    addressLine1: currentWarehouse.addressLine1,
                    addressLine2: currentWarehouse.addressLine2,
                    city: currentWarehouse.city,
                    name: currentWarehouse.name,
                    phone: lastShippingInfo.fromPhoneOverride,
                    postalCode: currentWarehouse.postalCode,
                    stateOrProvinceId: currentWarehouse.stateOrProvinceId,
                  },
                });
              }
              dispatch(openDoc({ name: 'Shipment Labels', url }));
              sessionStorage.setItem('selectedIds', JSON.stringify(selectedIds));
              const totalPrice = successfullyShipped.reduce((acc, shipment) => shipment.price, 0);

              const analytics = getAnalytics();
              logEvent(analytics, 'new_shipment_purchase', {
                value: totalPrice,
              });

              window.dataLayer = window.dataLayer || [];
              window.dataLayer.push({
                event: 'new_shipment_purchase',
                value: totalPrice,
              });

              if (!shipmentsExist) {
                logEvent(analytics, 'first_shipment', {
                  value: totalPrice,
                });

                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                  event: 'first_shipment',
                  value: totalPrice,
                });
              }

              await dataProvider.post('addressUsed', { data: { warehouseId } });
            }
            if (shipments.length !== successfullyShipped.length) {
              const failedShipments = shipments?.filter((shipment) => shipment.errors.length);
              notify(
                <div>{failedShipments.length} orders haven't been processed. Please, try again</div>
              );
            }
            setIsLoading(false);
            if (
              shipments.some((shipment) => shipment?.errors[0]?.code === 'AUTO_REFILL_FAILED_ERROR')
            ) {
              refill(false).then(() => {
                setRates((prev) =>
                  prev.map((rate) => {
                    if (rate.price?.alerts?.length) {
                      rate.price.alerts = null;
                    }
                    return rate;
                  })
                );
              });
            }
          },
          onerror(error) {
            removeNoInternetTimeout(receiveTimeout);
            setIsLoading(false);
            setLabelsProcessingText('Printing labels');
            throw error;
          },
        }).catch((error) => notify(error.message));
      })
      .catch((error) => {
        removeNoInternetTimeout(receiveTimeout);
        setIsLoading(false);
        setLabelsProcessingText('Printing labels');
        notify(error.message);
      });
  };

  const resetShippingRates = () => {
    if (controller) controller.abort();
    setShowRates(false);
    setSelectedIds(JSON.parse(sessionStorage.getItem('selectedIds') ?? '[]'));
  };

  const requestRateEdit = (requestData, id) => {
    setLabelsProcessingText('Calculating rates');
    let currentRate;
    return Auth.currentSession()
      .then((aws) => {
        const token = aws['idToken'].jwtToken;
        fetchEventSource(`${process.env.REACT_APP_DATA_PROVIDER_URL}/calc/group-prices`, {
          method: 'POST',
          headers: {
            Accept: 'text/event-stream',
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify([requestData]),
          onopen(res) {
            res.ok && res.status === 200 ? setIsLoading(true) : notify(res);
          },
          onmessage(event) {
            currentRate = mapRateEmailWarning(JSON.parse(event.data), recipientTrackingEmail);
          },
          onclose() {
            setRates((prev) => [currentRate, ...prev.filter((rate) => rate.orderId !== id)]);
            setSelectedIds((prev) => [id, ...prev.filter((selectedId) => id !== selectedId)]);
            setIsLoading(false);
          },
          onerror(error) {
            notify(typeof error === 'string' ? error : error.message);
            setIsLoading(false);
            throw error;
          },
        }).catch((error) => notify(error.message));
      })
      .catch((error) => notify(error.message));
  };

  const handlePhoneSubmit = (fromPhoneOverride) => {
    const rateId = rates[0].orderId;
    calculateGroupPrices({ ...lastShippingInfo[rateId], fromPhoneOverride });
    setLastShippingInfo((prev) => ({ ...prev, fromPhoneOverride }));
  };

  return (
    <>
      {(!!rates.length || isLoading) && showRates ? (
        <GroupInfo
          group={group}
          title={
            rates.some((rate) => rate.isShipped) && !isLoading ? 'Your Shipment' : 'Shipping Rates'
          }
          resetShippingRates={resetShippingRates}
          basePath="/orders"
          hidden={rates?.length && rates.every((rate) => rate.isShipped)}
        />
      ) : (
        <GroupInfo
          group={group}
          title={'Choose Shipping'}
          basePath="/orders/groups"
          to={location?.state?.fromOrders ? '' : 'show'}
        />
      )}
      {!showRates ? (
        <GroupedForm
          selectedIds={selectedIds}
          group={group}
          onSubmit={calculateGroupPrices}
          warehousePresets={warehousePresets}
          carriers={carriers}
          packageTypes={packageTypes}
          updatedGroupRule={updatedGroupRule}
          setUpdatedGroupRule={setUpdatedGroupRule}
          groupSettings={groupSettings}
          showGroupSwitch={showGroupSwitch}
          services={services}
          deliveryTimes={deliveryTimes}
          setGroupSettings={setGroupSettings}
        />
      ) : !!rates?.length ? (
        <GroupedPrices
          rates={rates}
          lastShippingInfo={lastShippingInfo}
          setLastShippingInfo={setLastShippingInfo}
          packageTypes={packageTypes}
          carriers={carriers}
          setRates={setRates}
          buyLabels={buyLabels}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          selectedIds={selectedIds}
          setSelectedIds={setSelectedIds}
          labelsProcessingText={labelsProcessingText}
          setLabelsProcessingText={setLabelsProcessingText}
          successfulShipments={successfulShipments}
          deliveryTimes={deliveryTimes}
          services={services}
          recipientTrackingEmail={recipientTrackingEmail}
          setRecipientTrackingEmail={setRecipientTrackingEmail}
          setShowFixModal={setShowFixModal}
          requestRateEdit={requestRateEdit}
          {...props}
        />
      ) : (
        <CircularProgress />
      )}
      {showFixModal && (
        <FromPhoneModal
          showFixModal={showFixModal}
          setShowFixModal={setShowFixModal}
          setRates={setRates}
          setLastShippingInfo={setLastShippingInfo}
          handlePhoneSubmit={handlePhoneSubmit}
        />
      )}
    </>
  );
};

export default memo(GroupedCalculator);
