import React, { useState } from 'react';
import { MenuItem } from '@material-ui/core';
import usePrintLabel from 'hooks/usePrintLabel';
import ActionButton from 'components/buttons/ActionButton';
import ReprintAlertDialog from './ReprintAlertDialog';

const STATUSES_ALLOW_REPRINT = ['SHIPPED', 'POTENTIALLY_LOST'];

const ReprintLabelButton = ({ data }) => {
  const { printingLabel, printLabel } = usePrintLabel({
    id: data.id,
    isReprint: true,
  });
  const [openAlert, setOpenAlert] = useState<boolean>(false);

  if (!data.account.id) return null;

  const onClick = () => {
    const hideAlert = JSON.parse(localStorage.getItem('hideReprintAlert') ?? 'false');
    hideAlert ? printLabel() : setOpenAlert(true);
  };

  return (
    <>
      <ActionButton
        data-test="reprint-label-button"
        color="primary"
        disabled={printingLabel || !STATUSES_ALLOW_REPRINT.includes(data.shipmentStatus.status)}
        onClick={onClick}>
        Reprint Label
      </ActionButton>
      <ReprintAlertDialog open={openAlert} printLabel={printLabel} setOpenAlert={setOpenAlert} />
    </>
  );
};

const ReprintLabelActionField = (props: any) => {
  const { printLabel } = usePrintLabel({
    id: props.record.id,
    isReprint: true,
  });

  const [openAlert, setOpenAlert] = useState<boolean>(false);

  if (!STATUSES_ALLOW_REPRINT.includes(props.record.shipmentStatus.status) || props.voidedLabel)
    return null;

  const onClick = () => {
    const hideAlert = JSON.parse(localStorage.getItem('hideReprintAlert') ?? 'false');
    hideAlert ? printLabel() : setOpenAlert(true);
  };

  return (
    <>
      <MenuItem onClick={onClick}>Reprint label</MenuItem>
      <ReprintAlertDialog open={openAlert} printLabel={printLabel} setOpenAlert={setOpenAlert} />
    </>
  );
};

export { ReprintLabelButton, ReprintLabelActionField };
