import React, { memo } from 'react';

import { Input, Select } from 'components/form';
import { taxNumber } from 'components/form/validationRules';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  select: {
    maxWidth: 70,
    margin: 0,
    justifyContent: 'center',
    minWidth: 70,
  },
  taxNumberInput: {
    minWidth: 200,
    marginTop: 16,
    width: '100%',
  },
  taxContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    height: 80,
  },
  taxNumberRoot: {
    marginRight: theme.spacing(2),
    width: '100%',
  },
}));

interface ITaxIdInputProps {
  options: any;
  sourceName?: string;
  label?: string;
  inputLabel?: string;
  handleTypeChange?: (value: string) => void;
  taxNumberTypes: any;
  handleTaxIdChange?: (value: string) => void;
  isProfile?: boolean;
}

const TaxIdInput = (props: ITaxIdInputProps) => {
  const classes = useStyles();
  const {
    options,
    sourceName,
    taxNumberTypes,
    label,
    inputLabel,
    handleTypeChange,
    handleTaxIdChange,
    isProfile,
  } = props;
  return (
    <div className={classes.taxContainer}>
      <Select
        name={sourceName ? `${sourceName}.taxIdType` : 'taxIdType'}
        label={label}
        options={options}
        classes={{ root: classes.select, select: classes.select }}
        onChange={handleTypeChange}
      />
      <Input
        name={sourceName ? `${sourceName}.taxId` : 'taxId'}
        inputProps={{ placeholder: inputLabel }}
        parse={(v) => v.trim().toUpperCase()}
        classes={{
          input: classes.taxNumberInput,
          root: classes.taxNumberRoot,
        }}
        validate={taxNumber(sourceName, taxNumberTypes, isProfile)}
        onChange={handleTaxIdChange}
      />
    </div>
  );
};

export default memo(TaxIdInput);
