import { get, set, isEmpty, toInteger } from 'lodash';

export const required = (value) => {
  if (Array.isArray(value)) {
    return value.length ? undefined : 'Required';
  }

  if (typeof value === 'string') {
    return value.trim() ? undefined : 'Required';
  }

  return value || typeof value === 'number' ? undefined : 'Required';
};

export const requiredIf = (testFn) => (value, allValues) =>
  testFn(value, allValues) ? required(value) : undefined;

export const requireDefined = (value) => (value === undefined ? 'Required' : undefined);

export const requiredIfEmpty = (fieldName) => (value, allValues) =>
  value || get(allValues, fieldName, false) ? undefined : 'Required';

export const alwaysCorrect = () => () => undefined;

export const maxLength = (max) => (value) =>
  value && value.length > max ? `Must be ${max} characters or less` : undefined;

export const maxLength15 = maxLength(15);

export const minLength = (min) => (value) =>
  value && value.length < min ? `Must be ${min} characters or more` : undefined;

export const minLength2 = minLength(2);

export const number = (value) => (value && isNaN(Number(value)) ? 'Must be a number' : undefined);

export const positive = (value) => (value && value > 0 ? undefined : 'Must be positive');

export const minValue = (min) => (value) =>
  value && value < min ? `Must be at least ${min}` : undefined;

export const minValue13 = minValue(13);

export const maxValue = (max) => (value) =>
  value && value > max ? `Must be less or equal to ${max}` : undefined;

export const email = (value) =>
  !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,24}$/i.test(value) ? 'Invalid email address' : undefined;

export const emailOptional = (value) => {
  if (!value) return undefined;
  return email(value);
};

export const tooYoung = (value) =>
  value && value < 13 ? 'You do not meet the minimum age requirement!' : undefined;

export const aol = (value) =>
  value && /.+@aol\.com/.test(value) ? 'Really? You still use AOL for your email?' : undefined;

export const alphaNumeric = (value) =>
  value && /[^a-zA-Z0-9 ]/i.test(value) ? 'Only alphanumeric characters' : undefined;

export const phoneNumber = (value) =>
  value && !/^\+[1-9]\d{1,14}$/i.test(value)
    ? 'Invalid phone number. Please start with + and indicate country code. Phone number can be up to fifteen digits. No spaces or other characters are allowed.'
    : undefined;

export const usPhoneNumber = (value) =>
  value && !/^\d{10}$/i.test(value)
    ? 'Invalid phone number, must be number of 10 digits in length'
    : undefined;

export const password = (value) =>
  value && value.length >= 6 && value.match(/[a-z]/) && value.match(/[A-Z]/) && value.match(/\d/)
    ? undefined
    : 'Password of at least 6 characters must contain at least one uppercase letter, one lowercase letter and one number';

export const confirmPassword = (value, password) =>
  password && value !== password ? 'Password does not match the confirm password' : undefined;

export const mustBeChecked = (value) => (value && Boolean(value) ? undefined : 'Must be checked');

export const regex = (pattern, message) => (value) =>
  !isEmpty(value) && !pattern.test(value) ? message : undefined;

export const composeValidators =
  (...validators) =>
  (value, values) =>
    validators.reduce((error, validator) => error || validator(value, values), undefined);

export const onlyUSPhones = (value) =>
  value ? `+1${value.replace('+1', '').replace(/[^\d]/g, '')}` : value;

export const weightPositive = (lbPath, ozPath, isLastStep) => (values) => {
  const lb = toInteger(get(values, lbPath) ?? 0);
  const oz = toInteger(get(values, ozPath) ?? 0);
  if (!isLastStep) return;
  if (lb === 0 && oz === 0) {
    return set({}, lbPath, 'Weight should be positive');
  }
};

export const weightFormValidator = (packageTypes, typePath, lbPath, ozPath) => (values) => {
  const lb = toInteger(get(values, lbPath) ?? 0);
  const oz = toInteger(get(values, ozPath) ?? 0);

  const packageType = packageTypes?.find((i) => i.code === get(values, typePath));

  if (!packageType) {
    return;
  }

  const { maxWeightLb, maxWeightOz, flatRate } = packageType;

  if (lb === 0 && oz === 0 && !flatRate) {
    return set({}, lbPath, 'Weight should be positive');
  }

  if (!maxWeightLb && !maxWeightOz) {
    return;
  }

  if (16 * lb + oz > 16 * maxWeightLb + maxWeightOz) {
    return set({}, lbPath, `Weight should be less or equal to ${maxWeightLb}lb ${maxWeightOz}oz`);
  }
};

export const requiredAddress = (value) => {
  if (required(value) === undefined) return maxLength(50)(value);
  else return 'Required';
};

export const address = (value) => {
  return maxLength(50)(value);
};

export const countryPhoneNumber = (country, value) =>
  country === 'US' ? usPhoneNumber(value) : phoneNumber(value);

export const requiredPhoneNumber = (country, value) =>
  value ? countryPhoneNumber(country, value) : required(value);

export const usPhoneNumberWithCountryCode = (value) => {
  if (!value || /^\+1\d{10}$/i.test(value)) return undefined;
  else
    return 'Invalid phone number. Please start with +1. Phone number must be 11 digits. No spaces or other characters are allowed.';
};

export const optionalValidator = (validator) => (value) => {
  if (value === undefined) return undefined;
  else return validator(value);
};

export const warehousePhoneNumber = (value) => {
  return value && value !== '+1' && !/^\+1\d{10}$/i.test(value)
    ? 'Only US phone numbers are accepted.'
    : undefined;
};

export const usPhoneRequired = (value) => (value ? usPhoneNumber(value) : required(value));

export const taxNumber = (sourceName, availableTypes, isProfile) => (value, allValues) => {
  if (isEmpty(value)) return isProfile ? 'Required' : undefined;
  const currentType = get(allValues, sourceName ? `${sourceName}.taxIdType` : 'taxIdType');
  if (currentType) {
    const type = availableTypes?.find((item) => item.taxIdType === currentType);
    if (type && !new RegExp(type?.javaRegexp).test(value)) {
      return `Expected format: ${type?.example}`;
    }
  }
};

export const creditCodeValidator = (value) =>
  value?.length !== 10
    ? 'Invalid shipment credit code. Make sure you have entered the code correctly.'
    : undefined;
