import { GET_ONE, UPDATE } from 'react-admin';
import Auth from '@aws-amplify/auth';
import { pick, omit } from 'lodash';

export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';

export default async (type, resource, params) => {
  switch (type) {
    case GET_ONE:
      return Auth.currentAuthenticatedUser({
        bypassCache: params.bypassCache === undefined ? true : params.bypassCache,
      })
        .then(({ attributes }) => ({
          data: {
            ...pick(attributes, ['given_name', 'family_name', 'email', 'phone_number', 'sub']),
            id: params.id,
          },
        }))
        .catch((error) => {
          if (error === 'The user is not authenticated') {
            localStorage.removeItem('verificationClosed');
            Auth.signOut();
          }
        });
    case UPDATE:
      const user = await Auth.currentAuthenticatedUser({ bypassCache: true });
      await Auth.updateUserAttributes(user, omit(params.data, ['id']));
      const session = await Auth.currentSession();
      await new Promise((resolve, reject) => {
        user.refreshSession(session.getRefreshToken(), (err, res) => {
          if (err) {
            reject(err);
          } else {
            resolve(res);
          }
        });
      });
      return { data: params.data };
    case CHANGE_PASSWORD:
      return Auth.currentAuthenticatedUser()
        .then((user) => Auth.changePassword(user, params.data.oldPassword, params.data.newPassword))
        .then(() => ({ data: {} }));
  }
};
