import React from 'react';
import { useCreateController } from 'ra-core';
import WarehousePresetForm from './WarehousePresetForm';
import { Dialog } from '@material-ui/core';

const WarehousePresetCreateDialog = (props) => {
  const { open, onSubmitted, onClose } = props;

  const controllerProps = useCreateController({
    ...props,
    resource: 'warehousePresets',
    undoable: false,
  });

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <WarehousePresetForm
        {...props}
        {...controllerProps}
        redirect={null}
        onSubmitted={onSubmitted}
        xs={12}
        onBackClick={onClose}
      />
    </Dialog>
  );
};

export default WarehousePresetCreateDialog;
