import React from 'react';
import { get } from 'lodash';
import classNames from 'classnames';
import { createStyles, withStyles, Typography, Chip } from '@material-ui/core';
import { LogoImage, TwoLinesLabel, SellingChannelStatus } from 'components/common';
import stripTags from 'services/helpers/stripTags';
import moment from 'moment';

const styles = (theme) =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
    },

    logo: {},

    primary: {},

    secondary: {},

    reverse: {
      flexDirection: 'row-reverse',

      '& $logo': {
        marginLeft: theme.spacing(3),
        marginRight: 0,
      },
    },
    chip: {
      height: 18,
      fontSize: 12,
      fontWeight: 'bold',
      marginLeft: '1rem',
    },

    confirmationNumberWrapper: {
      marginLeft: '16px',
    },
  });

const ProviderField = (props) => {
  const {
    classes,
    record,
    imageSource,
    source,
    secondarySource,
    smallProviderLogo,
    reverse,
    hideLabel = false,
    label = null,
    secondarySourceEmptyText = '',
    badgeTypeSource = null,
    badgeTypes = {},
    twoLabelsClassName,
    showSCStatus = false,
    confirmationNumberSource,
    isSummary = false,
  } = props;

  const image = get(record, imageSource);
  const primary = get(record, source || label);
  const badgeType = get(record, badgeTypeSource) || '';
  const badge = get(badgeTypes, badgeType) || null;
  const secondary = stripTags(get(record, secondarySource) || secondarySourceEmptyText);
  const confirmationNumberStr = get(record, confirmationNumberSource) || '';
  const fromTime = get(record, 'fromTime');
  const toTime = get(record, 'toTime');

  return (
    <div
      className={classNames(classes.root, reverse && classes.reverse)}
      data-test="shippingProvider">
      <LogoImage className={classes.logo} src={image} small={smallProviderLogo} />
      {!hideLabel && !label && (
        <>
          {!isSummary ? (
            <TwoLinesLabel
              classes={{
                primary: classes.primary,
                secondary: classes.secondary,
              }}
              className={twoLabelsClassName}
              primary={primary}
              secondary={
                !showSCStatus ? (
                  fromTime && toTime ? (
                    <>
                      {secondary} <br />
                      {moment(fromTime, 'HH:mm').format('hh:mm A')} -{' '}
                      {moment(toTime, 'HH:mm').format('hh:mm A')}
                    </>
                  ) : (
                    secondary
                  )
                ) : (
                  <SellingChannelStatus market={record} showIcon={false} />
                )
              }
              small={smallProviderLogo}
              align={reverse ? 'right' : 'left'}
            />
          ) : (
            <div className={twoLabelsClassName}>
              <Typography
                className={classes.primary}
                component="div"
                variant="body1"
                color="textPrimary"
                align={reverse ? 'right' : 'left'}
                data-test="primary">
                {primary}
              </Typography>
              <Typography
                className={classes.secondary}
                component="div"
                variant="body2"
                color="textSecondary"
                align={reverse ? 'right' : 'left'}
                data-test="secondary">
                {!showSCStatus ? (
                  secondary
                ) : (
                  <SellingChannelStatus market={record} showIcon={false} />
                )}
              </Typography>
            </div>
          )}
          {!!confirmationNumberStr && (
            <div className={classNames(classes.confirmationNumberWrapper)}>
              <TwoLinesLabel
                classes={{
                  primary: classes.primary,
                  secondary: classes.secondary,
                }}
                className={twoLabelsClassName}
                primary={'Confirmation number'}
                secondary={confirmationNumberStr}
                small={smallProviderLogo}
                align={reverse ? 'right' : 'left'}
              />
            </div>
          )}
        </>
      )}
      {badge && (
        <Chip
          className={classNames(classes.chip)}
          style={{
            color: badge.color,
            background: badge.bgColor,
          }}
          label={badge.name}
        />
      )}
      {!hideLabel && label && (
        <Typography component="div" variant="body1" color="textPrimary">
          {primary}
        </Typography>
      )}
    </div>
  );
};

export default withStyles(styles)(ProviderField);
