import React, { memo, useState, useMemo, useEffect, useCallback } from 'react';
import { keyBy, isEqual } from 'lodash';
import {
  Card,
  makeStyles,
  Collapse,
  Typography,
  Button,
  CircularProgress,
  FormControlLabel,
  Checkbox,
  Tooltip,
} from '@material-ui/core';
import { useDataProvider } from 'react-admin';
import { useDispatch } from 'react-redux';
import { InfoOutlined } from '@material-ui/icons';

import { Datagrid } from 'components/list';
import { OrderIdField, PriceField, StoreField } from 'components/fields';
import ShippingServiceField from 'components/fields/ShippingServiceField';
import DeliveryTimeField from 'components/fields/DeliveryTimeField';
import RateEditField from './RateEditField';
import useCustomNotify from 'hooks/useCustomNotify';
import Dialog from 'components/common/Dialog';
import useRefillDialog from 'hooks/useRefillDialog';
import formatPrice from 'services/helpers/formatPrice';
import RateStatusField from './RateStatusField';
import { BulkPrintPackingSlipsButton } from './BulkPrintPackingSlipsButton';
import { openDoc } from 'store/actions/lightboxActions';
import { ActionField } from 'components/fields';
import ColumnsConfigButton from 'components/layout/ColumnsConfigButton';
import { PrintPackingSlipActionField } from 'pages/shipments/components/PackingSlip';
import { ReprintLabelActionField } from 'pages/shipments/components/ReprintLabel';
import { ReturnLabelActionField } from 'pages/shipments/components/ReturnLabel';
import { VoidLabelActionField } from 'pages/shipments/components/VoidLabel';
import { CopyTrackNumberActionField } from 'pages/shipments/components/CopyTrackNumber';
import UpsActivationFlow from 'pages/shipmentAccounts/wizards/ups/UpsActivationFlow';
import { setPaymentAmount, removePaymentAmount } from 'store/actions/refillActions';
import mapRateEmailWarning from '../../utils/mapRateEmailWarning';

const useStyles = makeStyles((theme) => ({
  itemsContainer: {
    marginLeft: theme.spacing(6.8),
    marginBottom: theme.spacing(3),
  },
  quantity: {
    fontSize: '0.8rem',
    marginLeft: theme.spacing(1),
    paddingRight: theme.spacing(2),
  },
  sku: {
    fontSize: '0.8rem',
    marginLeft: theme.spacing(1.5),
  },
  name: {
    fontSize: '0.8rem',
    marginLeft: theme.spacing(2),
  },
  main: {
    display: 'flex',
  },
  content: {
    marginTop: 0,
    transition: theme.transitions.create('margin-top'),
    position: 'relative',
    overflow: 'visible',
    flex: '1 1 auto',
    [theme.breakpoints.down('xs')]: {
      boxShadow: 'none',
    },
  },
  multipleButton: {
    padding: theme.spacing(1, 2.5),
    lineHeight: '16px',
    alignSelf: 'flex-end',
    justifySelf: 'flex-end',
  },
  root: {
    position: 'sticky',
    top: 0,
    zIndex: theme.zIndex.drawer,
  },

  container: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1.5, 2),
    borderBottom: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.spacing(2, 2, 0, 0),
    backgroundColor: theme.palette.background.paper,
  },

  caption: {
    fontSize: 14,
    color: theme.palette.primary.light,
    marginLeft: 20,
  },

  actions: {
    display: 'flex',
    marginLeft: 'auto',
    alignItems: 'flex-end',

    '& > *': {
      marginLeft: theme.spacing(2),
    },
  },
  totalPriceLabel: {
    fontWeight: 'bold',
    marginRight: theme.spacing(1),
  },
  labelsProcessingProgress: {
    marginRight: theme.spacing(1),
  },
  emailTracking: {
    height: 20,
    marginRight: 0,
    paddingTop: theme.spacing(1),
  },
  actionsContainer: {
    display: 'flex',
    alignSelf: 'flex-end',
    alignItems: 'flex-end',
    flexDirection: 'column',
    marginLeft: 'auto',
  },
  activateUpsButton: {
    padding: theme.spacing(1, 2.5),
    lineHeight: '16px',
  },
  tooltip: {
    maxWidth: 430,
    padding: 10,
    borderRadius: 16,
    lineHeight: 1.2,
    zIndex: 3,
    fontSize: 16,
  },
  packingSlipsButton: {
    lineHeight: '22px',
  },
  collapseContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
}));

const PLATFORM_FEE = 0.05;

const prepareRequestData = (lastShippingInfo, selectedIds, rates, fromPhoneOverride) => {
  const requestData = {
    companyLocationPresetId: lastShippingInfo[`${selectedIds[0] ?? rates[0].orderId}`].from.id,
    fromPhoneOverride,
    orderShipmentSelections: selectedIds
      .filter((id) => !rates.find((rate) => rate.orderId === id).isShipped)
      .map((id) => {
        const currentRate = rates.find((rate) => rate.orderId === id);
        const currentOrderInfo = lastShippingInfo[`${id}`];
        if (!currentRate.price.options.length) return undefined;
        return {
          orderId: id,
          insurance: currentOrderInfo.options.insurance,
          signature: currentOrderInfo.options.signature,
          packageInfo: { ...currentOrderInfo.packageInfo[0] },
          serviceCode: currentRate?.price.options[0].serviceClass.code,
          serviceName: currentRate?.price.options[0].serviceClass.name,
          shipmentAccount: currentRate?.price.accountInfo,
          price: currentRate.price.options[0].price,
          nonDiscountedPrice: currentRate.price.options[0].nonDiscountedPrice,
          shipmentDate: currentOrderInfo.shipmentDate,
        };
      }),
  };
  requestData.orderShipmentSelections = requestData.orderShipmentSelections.filter(
    (order) => !!order
  );
  return requestData;
};

const BuyLabelsButton = (props) => {
  const classes = useStyles();
  const refill = useRefillDialog();
  const notify = useCustomNotify();
  const dispatch = useDispatch();

  const {
    selectedIds,
    lastShippingInfo,
    rates,
    buyLabels,
    isLoading,
    labelsProcessingText,
    recipientTrackingEmail,
    setIsLoading,
    setLabelsProcessingText,
    totalFilteredPrice,
    loadBalance,
  } = props;

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const createLabels = () => {
    const requestData = prepareRequestData(
      lastShippingInfo,
      selectedIds,
      rates,
      lastShippingInfo.fromPhoneOverride
    );
    buyLabels({ ...requestData, recipientTrackingEmail });
  };

  const handleClick = async (e) => {
    try {
      e.stopPropagation();
      setLabelsProcessingText('Buying labels');
      setIsLoading(true);

      const { balance, autoRefillEnabled, failedCards } = await loadBalance();

      if (
        balance < totalFilteredPrice &&
        (!autoRefillEnabled || (autoRefillEnabled && failedCards))
      ) {
        if (!autoRefillEnabled) {
          dispatch(setPaymentAmount(parseFloat((totalFilteredPrice - balance).toFixed(2))));
        }

        refill(!autoRefillEnabled).then(() => {
          setIsLoading(false);
          dispatch(removePaymentAmount());
        });
      } else if (rates.some((rate) => rate.price.alerts?.length)) {
        setIsOpen(true);
        setIsLoading(false);
      } else {
        createLabels();
      }
    } catch (error) {
      setIsLoading(false);
      notify(error.message);
    }
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleSubmit = () => {
    createLabels();
    setIsOpen(false);
  };

  return (
    <>
      <Button
        className={classes.multipleButton}
        {...props}
        color="primary"
        variant="contained"
        size="small"
        disabled={
          isLoading ||
          rates?.every((rate) => rate.price?.alerts?.length) ||
          rates
            ?.filter((rate) => selectedIds.includes(rate.orderId))
            .some((rate) => rate.price.accountInfo.marketingOnly)
        }
        onClick={handleClick}>
        {isLoading ? (
          <>
            <CircularProgress size={12} className={classes.labelsProcessingProgress} />
            {labelsProcessingText}
          </>
        ) : (
          'Buy labels'
        )}
      </Button>
      <Dialog
        open={isOpen}
        confirmText={'Continue'}
        title={
          <>
            <div>Warning</div>
          </>
        }
        onCancel={handleClose}
        onConfirm={handleSubmit}>
        <Typography color="textPrimary">
          There are orders with errors (highlighted in red). To proceed without these orders, click
          on Continue. To go back and fix the errors, click Cancel.
        </Typography>
      </Dialog>
    </>
  );
};

const PrintLabelsButton = (props) => {
  const { rates, selectedIds, successfulShipments } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const dataProvider = useDataProvider();
  const notify = useCustomNotify();

  const handleClick = () => {
    if (!successfulShipments?.length || !selectedIds?.length) return;
    const labelsForReprint = successfulShipments
      ?.filter((shipment) => selectedIds?.includes(shipment.order.id))
      ?.map(({ id }) => id);
    dataProvider
      .create('groupLabels', {
        data: labelsForReprint,
      })
      .then(({ data: { url } }) => {
        dispatch(openDoc({ name: 'Shipment Labels', url }));
      })
      .catch((error) => notify(error.message));
  };

  return (
    <Button
      className={classes.multipleButton}
      {...props}
      color="primary"
      variant="contained"
      size="small"
      onClick={handleClick}
      disabled={rates
        .filter((rate) => selectedIds.includes(rate.orderId))
        .every((rate) => !rate.isShipped)}>
      Print labels
    </Button>
  );
};

const BulkActions = (props) => {
  const classes = useStyles();
  const dataProvider = useDataProvider();
  const notify = useCustomNotify();

  const {
    selectedIds,
    lastShippingInfo,
    rates,
    buyLabels,
    isLoading,
    labelsProcessingText,
    successfulShipments,
    setRates,
    recipientTrackingEmail,
    setRecipientTrackingEmail,
    setIsLoading,
    setLabelsProcessingText,
  } = props;

  const [freeLabels, setFreeLabels] = useState<number | null>(null);
  const [balanceLoading, setBalanceLoading] = useState<boolean>(false);

  const loadBalance = useCallback(async () => {
    try {
      setBalanceLoading(true);

      const { data: balanceSettings } = await dataProvider.get('billingBalance');

      const balance = balanceSettings.find((balance) => balance.currency === 'USD')?.balance ?? 0;

      const freeLabels =
        balanceSettings.find((balance) => balance.currency === 'LABEL')?.balance ?? 0;

      const { data: billingSettings } = await dataProvider.get('billingSettings');

      const autoRefillEnabled = billingSettings.autoRefill.enabled;
      const failedCards = billingSettings.cards.find(
        (card) => card.cardId === billingSettings.autoRefill.defaultCardId
      )?.failedLastTime;

      setFreeLabels(freeLabels);

      return { balance, autoRefillEnabled, failedCards, freeLabels };
    } catch (error) {
      notify(error.message);
      return {};
    } finally {
      setBalanceLoading(false);
    }
  }, [dataProvider, notify]);

  useEffect(() => {
    loadBalance();
  }, [loadBalance]);

  const handleChange = () => {
    setRates((prev) => prev.map((rate) => mapRateEmailWarning(rate, !recipientTrackingEmail)));
    setRecipientTrackingEmail((prev) => !prev);
  };

  const { shipmentPrice, totalFilteredPrice } = useMemo(() => {
    let currentShipmentPrice = 0;
    let currentFilteredPrice = 0;

    rates.forEach((rate) => {
      if (rate.price.options?.length && selectedIds.includes(rate.orderId)) {
        currentShipmentPrice += rate?.price?.options[0]?.price;

        if (rate.price.accountInfo.rollo) {
          currentFilteredPrice += rate?.price?.options[0]?.price;
        }
      }
    });

    const totalPaidLabels =
      freeLabels === null
        ? 0
        : rates?.length < selectedIds?.length
        ? 0
        : selectedIds?.length > freeLabels
        ? selectedIds?.length - freeLabels
        : 0;

    const totalFee = totalPaidLabels * PLATFORM_FEE;

    const shipmentPrice = currentShipmentPrice + totalFee;
    const totalFilteredPrice = currentFilteredPrice + totalFee;

    return { shipmentPrice, totalFilteredPrice };
  }, [rates, selectedIds, freeLabels]);

  return (
    <div className={classes.root}>
      <Collapse in={!!selectedIds.length || isLoading} className={classes.collapseContainer}>
        <div className={classes.container}>
          <Typography variant="body1" className={classes.totalPriceLabel}>
            Total: Total: {!balanceLoading ? formatPrice(shipmentPrice) : 'Loading...'}
          </Typography>
          <Tooltip
            title={`Each tracking number you generate will cost 5 cents 
                which helps us to maintain the service. It is waived for your 
                first 200 shipments. This nominal fee is easily covered by savings 
                from Rollo’s discounted shipping rates (up to 90% off)`}
            placement={'bottom-start'}
            enterDelay={300}
            classes={{ tooltip: classes.tooltip }}>
            <InfoOutlined fontSize="small" />
          </Tooltip>
          <div className={classes.caption}>
            <b>{selectedIds.length}</b> selected
          </div>
          <div className={classes.actionsContainer}>
            <div className={classes.actions}>
              <BulkPrintPackingSlipsButton
                primary={false}
                ids={selectedIds}
                type="orders"
                isLoading={isLoading}
                className={classes.packingSlipsButton}
              />
              {successfulShipments?.length ? (
                <PrintLabelsButton
                  rates={rates}
                  selectedIds={selectedIds}
                  successfulShipments={successfulShipments}
                  setIsLoading={setIsLoading}
                />
              ) : (
                <BuyLabelsButton
                  selectedIds={selectedIds}
                  lastShippingInfo={lastShippingInfo}
                  rates={rates}
                  buyLabels={buyLabels}
                  isLoading={isLoading}
                  labelsProcessingText={labelsProcessingText}
                  setRates={setRates}
                  recipientTrackingEmail={recipientTrackingEmail}
                  setIsLoading={setIsLoading}
                  setLabelsProcessingText={setLabelsProcessingText}
                  totalFilteredPrice={totalFilteredPrice}
                  loadBalance={loadBalance}
                />
              )}
            </div>
            {!successfulShipments?.length && (
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    onClick={handleChange}
                    checked={recipientTrackingEmail}
                    disabled={isLoading}
                  />
                }
                label="Email tracking to recipient"
                className={classes.emailTracking}
              />
            )}
          </div>
        </div>
      </Collapse>
    </div>
  );
};

const ShipmentMenuField = (props) => {
  return (
    <ActionField header={ColumnsConfigButton} textAlign="center">
      <ReturnLabelActionField {...props} />
      <ReprintLabelActionField {...props} />
      <PrintPackingSlipActionField {...props} />
      <VoidLabelActionField {...props} />
      <CopyTrackNumberActionField {...props} />
    </ActionField>
  );
};

const ShipmentActionsField = (props) => {
  const {
    initData,
    packageTypes,
    carriers,
    onSubmit,
    isLoading,
    textAlign,
    disabled,
    updateError,
    setUpdateError,
    successfulShipments,
    record,
    deliveryTimes,
    setRates,
  } = props;

  const shipmentRecord = successfulShipments?.find(
    (shipment) => shipment.order.id === record?.orderId
  );

  return record?.isShipped && shipmentRecord ? (
    <ShipmentMenuField
      {...props}
      record={shipmentRecord}
      setRates={setRates}
      voidedLabel={record.voidedLabel}
    />
  ) : (
    <RateEditField
      initData={initData}
      packageTypes={packageTypes}
      carriers={carriers}
      onSubmit={onSubmit}
      isLoading={isLoading}
      textAlign={textAlign}
      disabled={disabled}
      updateError={updateError}
      setUpdateError={setUpdateError}
      record={record}
      deliveryTimes={deliveryTimes}
    />
  );
};

const ActivateUpsField = (props) => {
  const {
    record: {
      price: { accountInfo },
    },
    match,
    history,
    location,
    setRates,
  } = props;
  const [upsActivationModalOpen, setActivationModalOpen] = useState<boolean>(false);
  const [upsId, setUpsId] = useState<string>('');
  const classes = useStyles();

  const onUpsActivation = (item) => {
    setActivationModalOpen(true);
    setUpsId(accountInfo.id);
  };

  const onUpsAccountActivated = () => {
    setActivationModalOpen(false);
    setRates((prev) =>
      prev.map((rate) => ({
        ...rate,
        price: {
          ...rate.price,
          accountInfo: {
            ...rate.price.accountInfo,
            marketingOnly: false,
          },
        },
      }))
    );
  };

  return (
    <>
      {accountInfo.marketingOnly && (
        <Button
          className={classes.activateUpsButton}
          color="primary"
          variant="contained"
          size="small"
          onClick={onUpsActivation}>
          Activate UPS
        </Button>
      )}
      <UpsActivationFlow
        upsId={upsId}
        open={upsActivationModalOpen}
        onCancel={() => setActivationModalOpen(false)}
        onConfirm={onUpsAccountActivated}
        match={match}
        history={history}
        location={location}
        basePath={match.params.url}
      />
    </>
  );
};

const GroupedPrices = (props) => {
  const {
    rates,
    lastShippingInfo,
    carriers,
    packageTypes,
    setRates,
    setLastShippingInfo,
    buyLabels,
    isLoading,
    setIsLoading,
    selectedIds,
    setSelectedIds,
    labelsProcessingText,
    setLabelsProcessingText,
    successfulShipments,
    deliveryTimes,
    recipientTrackingEmail,
    setRecipientTrackingEmail,
    setShowFixModal,
    requestRateEdit,
  } = props;
  const classes = useStyles();
  const dataProvider = useDataProvider();

  const [updateError, setUpdateError] = useState<string>('');

  const handleFixIt = () => {
    setShowFixModal(true);
  };

  const handleRateEdit = (data, rateId, recipient) => {
    const {
      comments,
      email,
      groupId,
      id,
      items,
      itemsSummary,
      lastUpdatedDate,
      marketAccount,
      marketplaceOrderId,
      note,
      orderStatus,
      purchaseDate,
      sellerOrderId,
      shippingAddress,
      shippingPaid,
      shippingService,
      tax,
      total,
      from,
      requestCarrier,
      selectedService,
      options,
      shipmentDate,
      packageInfo,
      selectedCarriers,
    } = data;

    const rateEditData = {
      carriers: deliveryTimes.some((time) => time.code === selectedService)
        ? selectedCarriers
        : requestCarrier,
      companyLocationPresetId: from.id,
      deliveryServiceCode: selectedService,
      insurance: options.insurance,
      signature: options.signature,
      shipmentDate: shipmentDate,
      packageInfo: {
        ...packageInfo[0],
      },
      orders: [rateId],
      fromPhoneOverride: lastShippingInfo.fromPhoneOverride,
    };

    const recipientEditData = {
      comments,
      email,
      groupId,
      id,
      items,
      itemsSummary,
      lastUpdatedDate,
      marketAccount,
      marketplaceOrderId,
      note,
      orderStatus,
      purchaseDate,
      sellerOrderId,
      shippingAddress,
      shippingPaid,
      shippingService,
      tax,
      total,
    };
    setIsLoading(true);
    setUpdateError('');

    if (!isEqual(recipientEditData, recipient)) {
      return dataProvider
        .update('orders', { id: rateId, data: recipientEditData })
        .catch((error) => {
          setUpdateError(error.message);
          setIsLoading(false);
          throw error;
        })
        .then(() => requestRateEdit(rateEditData, rateId))
        .then(() => setLastShippingInfo((prev) => ({ ...prev, [`${rateId}`]: data })));
    } else {
      return requestRateEdit(rateEditData, rateId)
        .then(() => setLastShippingInfo((prev) => ({ ...prev, [`${rateId}`]: data })))
        .catch((error) => {
          setIsLoading(false);
          throw error;
        });
    }
  };

  return (
    <>
      <div className={classes.main}>
        <Card className={classes.content}>
          <BulkActions
            {...props}
            selectedIds={selectedIds}
            lastShippingInfo={lastShippingInfo}
            rates={rates}
            buyLabels={buyLabels}
            isLoading={isLoading}
            labelsProcessingText={labelsProcessingText}
            successfulShipments={successfulShipments}
            setRates={setRates}
            recipientTrackingEmail={recipientTrackingEmail}
            setRecipientTrackingEmail={setRecipientTrackingEmail}
            setIsLoading={setIsLoading}
            setLabelsProcessingText={setLabelsProcessingText}
          />
          <Datagrid
            {...props}
            data={keyBy(rates, 'orderId')}
            ids={useMemo(() => rates.map(({ orderId }) => orderId), [rates])}
            currentSort={{}}
            basePath="orders"
            resource="orders"
            selectedIds={selectedIds}
            hasBulkActions
            onToggleItem={(currentId) => {
              selectedIds.includes(currentId)
                ? setSelectedIds((prev) => prev.filter((id) => id !== currentId))
                : setSelectedIds((prev) => [...prev, currentId]);
            }}
            onSelect={(ids) => setSelectedIds(ids)}>
            <OrderIdField label="Order No." source="marketplaceOrSellerOrderId" sortable={false} />
            <StoreField
              label="Carrier"
              source="store"
              tooltipSource="price.accountInfo.alias"
              logoSource="price.accountInfo.provider.logoUrl"
            />
            <ShippingServiceField label="Service" source="price" onClick={handleFixIt} />
            <DeliveryTimeField
              label="Delivery Time"
              source="price.options[0].deliveryDays"
              dateSource="price.options[0].estimatedArrivalDate"
              timeSource="price.options[0].estimatedArrivalTime"
            />
            <PriceField label="Price" source="price.options[0].price" />
            <RateStatusField label="Status" textAlign="center" />
            <ActivateUpsField {...props} setRates={setRates} />
            <ShipmentActionsField
              initData={lastShippingInfo}
              packageTypes={packageTypes}
              carriers={carriers}
              onSubmit={handleRateEdit}
              isLoading={isLoading}
              textAlign="right"
              disabled={rates.some((rate) => rate.isShipped)}
              updateError={updateError}
              setUpdateError={setUpdateError}
              successfulShipments={successfulShipments}
              deliveryTimes={deliveryTimes}
              setRates={setRates}
            />
          </Datagrid>
        </Card>
      </div>
    </>
  );
};

export default memo(GroupedPrices);
