import React, { useEffect, useState } from 'react';
import { useDataProvider } from 'react-admin';
import { Dialog } from 'components/common';
import UpsAgreements from './UpsAgreements';
import WarehousePresetCreateDialog from 'pages/warehousePresets/components/WarehousePresetCreateDialog';
import useCustomNotify from 'hooks/useCustomNotify';
import { Typography } from '@material-ui/core';

const UpsActivationFlow = (props) => {
  const { open, onConfirm, onCancel, upsId } = props;
  const dataProvider = useDataProvider();
  const notify = useCustomNotify();
  const [defaultId, setDefaultId] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const [openWarehouseModal, setOpenWarehouseModal] = useState(false);
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);

  useEffect(() => {
    if (upsId) {
      setDefaultId(upsId);
    }
  }, [upsId]);

  const getWarehouse = async () => {
    const { data: warehouses } = await dataProvider.getList('warehousePresets', {
      pagination: { perPage: 1 },
    });
    return warehouses;
  };

  const updateShipmentAccounts = async () => {
    await dataProvider.getList('shipmentAccounts', { filter: { usableOnly: true } });
  };

  const onUpsEnablingConfirm = async () => {
    const warehouses = await getWarehouse();
    if (warehouses.length > 0) {
      updateShipmentAccount(defaultId);
    } else {
      setOpenWarehouseModal(true);
    }
  };

  const updateShipmentAccount = (id) => {
    setSubmitting(true);
    dataProvider
      .create(
        'promoteShipmentAccount',
        {
          id,
        },
        { id: defaultId }
      )
      .then(() => {
        updateShipmentAccounts();
        setOpenConfirmationDialog(true);
      })
      .catch((error) => {
        onCancel();
        notify(typeof error === 'string' ? error : error.message || 'Error', 'warning');
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const onWarehousesCreated = async () => {
    await updateShipmentAccount(defaultId);
    setOpenWarehouseModal(false);
  };

  const handleConfirm = () => {
    setOpenConfirmationDialog(false);
    onConfirm();
  };
  return (
    <>
      <Dialog
        title="Congratulations!"
        open={!!openConfirmationDialog}
        onCancel={handleConfirm}
        submitting={submitting}
        cancelSize={12}
        cancelText="OK">
        <Typography>
          UPS has been successfully activated. You may now purchase your label
        </Typography>
      </Dialog>
      <WarehousePresetCreateDialog
        {...props}
        onSubmitted={onWarehousesCreated}
        open={openWarehouseModal}
        basePath={props.match.url}
        onClose={() => setOpenWarehouseModal(false)}
      />
      <Dialog
        title="Activate UPS"
        open={!!open}
        confirmText="I Accept"
        onConfirm={onUpsEnablingConfirm}
        onCancel={onCancel}
        submitting={submitting}>
        <UpsAgreements />
      </Dialog>
    </>
  );
};
export default UpsActivationFlow;
