export const SHOW_NOTIFICATION = 'Rollo/SHOW_NOTIFICATION';
export const HIDE_NOTIFICATION = 'Rollo/HIDE_NOTIFICATION';

export type NotificationType =
  | 'info'
  | 'warning'
  | 'error'
  | 'provider'
  | 'orders'
  | 'success'
  | 'connection'
  | 'billing';

export interface Notification {
  readonly content: any;
  readonly type: NotificationType;
  readonly id: string;
  readonly notificationOptions?: NotificationOptions;
}

export interface NotificationOptions {
  // The type of the notification
  autoHideDuration?: number;
  // Arguments used to translate the message
  messageArgs?: any;
  // If true, the notification shows an Undo button
  undoable?: boolean;
  // Param use for position
  anchorOrigin?: {
    vertical: string;
    horizontal: string;
  };
}

export interface ShowNotificationAction {
  readonly type: typeof SHOW_NOTIFICATION;
  readonly payload: Notification;
}

export interface HideNotificationAction {
  readonly type: typeof HIDE_NOTIFICATION;
  readonly payload: string;
}

export const showNotification = (
  content: any,
  type: NotificationType = 'info',
  id: string,
  notificationOptions?: NotificationOptions
): ShowNotificationAction => ({
  type: SHOW_NOTIFICATION,
  payload: {
    ...notificationOptions,
    type,
    content,
    id,
  },
});

export const hideNotification = (id: string): HideNotificationAction => ({
  type: HIDE_NOTIFICATION,
  payload: id,
});
