import React from 'react';
import { Typography, Button, makeStyles } from '@material-ui/core';
import { get } from 'lodash';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
  },
  fixError: {
    marginTop: theme.spacing(1),
    color: theme.palette.error.main,
    borderColor: theme.palette.error.main,
    height: 35,
    '&:hover': {
      borderColor: theme.palette.error.dark,
    },
  },
  errorMessage: {
    color: 'red',
  },
  warningMessage: {
    color: theme.palette.warning.main,
  },
}));

interface ShippingRatesMessage {
  code: string;
  description: string;
}

const ShippingServiceField = ({ record = {}, source = '', onClick, ...rest }) => {
  const classes = useStyles();
  const text: string = get(record, `${source}.options[0].serviceClass.name`) ?? '';
  const errors: [ShippingRatesMessage] = get(record, `${source}.alerts`) ?? [];
  const warnings: [ShippingRatesMessage] = get(record, `${source}.messages`) ?? [];

  return (
    <>
      {text && <Typography variant="body1">{text}</Typography>}
      {!!errors?.length &&
        errors.map((error) => (
          <Typography variant="body2" className={classes.errorMessage}>
            {error.description}
          </Typography>
        ))}
      {!!warnings?.length &&
        warnings.map((warning) => (
          <Typography variant="body2" className={classes.warningMessage}>
            {warning.description}
          </Typography>
        ))}
      {errors.some((error) => error.code === 'contactFrom') && (
        <Button
          variant="outlined"
          size="small"
          color="secondary"
          classes={{ root: classes.fixError }}
          onClick={onClick}>
          Fix it
        </Button>
      )}
    </>
  );
};

export default ShippingServiceField;
